const TRANSLATIONS = {
  es: {
    "all-rights-reserved": "Todos los derechos reservados",
    recipes: "recetas",
    category: "categoría",
    products: "productos",
    "search-results": "resultados-de-la-búsqueda",
    "get-the-recipe": "Accede a la receta",
    "all-recipes": "Todas las recetas",
    "featured-products": "Productos destacados",
    "where-to-buy": "Dónde comprar",
    "where-to-buy-link": "dónde-comprar",
    "download-the-recipe": "Descarga la receta",
    "download-pdf": "Descargar como PDF",
    "enter-search-term": "Ingrese el término de búsqueda aquí…",
    "end-of-results": "Fin de búsqueda",
    "search-again": "Busca de nuevo",
    "not-found": "Página No Encontrada",
    all: "Todos",
    "all-2": "Todas",
    "404-info":
      "Lo sentimos, pero no pudimos encontrar la página que busca. Es posible que la dirección se haya escrito incorrectamente o que la página ya no exista.",
    "first-name": "Nombre",
    "last-name": "Apellidos",
    phone: "Número de teléfono",
    "e-mail": "Dirección de email",
    "address-1": "Dirección postal 1",
    "address-2": "Dirección postal 2",
    country: "País",
    city: "Ciudad",
    county: "Distrito",
    "postal-code": "Código postal",
    "date-code": "Código de fecha",
    "model-number": "Número de modelo",
    subject: "Asunto",
    message: "Mensaje",
    "attach-documents": "Adjuntar documentos",
    language: "Spanish",
    failed: "Algo ha fallado, por favor inténtelo más tarde",
    success:
      "Su mensaje ha sido enviado. Nos pondremos en contacto con usted en breves para dar respuesta a su mensaje.",
    "required-field": "Información requerida",
    "max-length": "Longitud máxima",
    "you-have": "Quedan",
    "characters-limit": "caracteres de un límite de 500 caracteres.",
    "code-info":
      "El código de fecha generalmente se encuentra en la etiqueta de clasificación. Ocasionalmente, esto se encuentra en otra parte del producto. Este código debe comenzar con una letra seguida de tres números. (por ejemplo]: A123)",
    "choose-file": "Elegir archivo",
    "attach-file": "Adjuntar archivo",
    submit: "Enviar",
    "upload-info": "Si es necesario, se puede adjuntar un documento o imagen",
    "upload-file-sizes":
      'El tamaño máximo de los archivos es de 3MB y el formato debe ser "pdf,txt,doc,docx,jpg,png,jpeg,gif"',
    "all-products": "Todos los productos",
    traditional: "Tradicional",
    healthy: "Saludable",
    cocktails: "Cocktails / Mocktails",
    fun: "Divertido",
    "more-products": "Más productos",
  }
}

module.exports = {
  TRANSLATIONS,
}
