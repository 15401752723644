const theme = {
  oster: {
    main: "linear-gradient(90deg, rgba(19,25,57,1) 10%, rgba(89,137,199,1) 45%)",
    text: "#4f89c8",
    textSecond: "#051438",
    textAlt: "#fff",
    recipeCategories: {
      All: "#adcdec",
      Traditional: "rgba(216, 198, 184, 0.8)",
      Healthy: "rgba(191, 227, 211, 0.8)",
      Cocktails: "rgba(249, 169, 128, 0.8)",
      Fun: "rgba(214, 224, 64, 0.8)",
    },
    mainFont: "archerpro-medium-webfont",
    secondFont: "DIN Next LT Pro",
  },
}

export default theme
