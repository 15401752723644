import { TRANSLATIONS } from "./translations"
export const getLanguage = () => {
  if (typeof window === "undefined") return "es"
  if (window.location.pathname === "/") return "es"
  return window.location.pathname.split("/")[1]
}
export const getTranslation = key => {
  const lang = getLanguage()

  return TRANSLATIONS[lang ? lang : "es"][key]
}
