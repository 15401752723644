import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { Link } from "gatsby"
import Container from "../../components/common/container"
import NavIcons from "./nav-icons"
import Search from "./search"
import Navigation from "./navigation/navigation"
import { getLanguage } from "../../utils"

const StyledHeader = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  font-family: ${props => props.theme.oster.secondFont};
  background: ${props => props.theme.oster.main};
  z-index: 10;

  .container {
    display: flex;
    flex-direction: row;
  }

  h1 {
    position: relative;
    width: 100%;
    max-width: 16.5rem;
    margin: 0;
    padding: 1rem 0;
    z-index: 10;

    a {
      display: block;
      font-size: 0;
    }

    @media (max-width: 1024px) {
      max-width: 8rem;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
    }

    img {
      margin: 0;
    }
  }
`

const StyledAside = styled.aside`
  display: flex;
  margin-left: auto;

  @media (max-width: 1024px) {
    overflow: hidden;
    flex-direction: column;
    position: fixed;
    top: 3.6rem;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 0;
    background: #fff;
    transition: 400ms linear;

    ${props =>
      props.isOpen &&
      `
        max-height: 100rem;
      `};
  }

  a {
    position: relative;
    color: ${props => props.theme.oster.text};
    text-decoration: none;
    text-transform: uppercase;
    transition: 300ms linear;
    z-index: 10;

    @media (min-width: 1024px) {
      color: ${props => props.theme.oster.textAlt};
    }
  }
`

const StyledMobileButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0.7rem;
  font-size: 2rem;
  height: 3.8rem;
  width: 3.8rem;
  background: 0;
  color: #fff;
  padding: 0.1rem 0 0 0.2rem;
  border: 0;
  cursor: pointer;
  z-index: 2;
  transition: 200ms linear;

  svg {
    width: 100%;
    max-width: 2rem;

    path {
      fill: #fff;
    }
  }

  ${props =>
    props.isOpen &&
    `
      background-color :#fff;
      
        svg path{
          fill: #000;
        }
    `};

  @media (min-width: 1025px) {
    display: none;
  }

  &:focus,
  &:active {
    outline: none;
  }
`

const LogoImg = styled.img`
  max-width: 5.2rem;

  @media (min-width: 1024px) {
    max-width: 9.5rem;
  }
`

const Header = ({  mainMenuItems = [], navIcons = [] }) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: 1025px)`,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeSearch = () => {
    setIsSearchOpen(false)
  }

  const openSearch = () => {
    setIsSearchOpen(true)
  }

  const closeMenuAction = () => {
    setIsOpen(false)
    setIsSearchOpen(false)
  }

  return (
    <StyledHeader>
      <Container>
        <h1>
          <Link to={`/${getLanguage()}`} aria-label="Oster homepage">
            <LogoImg src={"/img/oster-logo.svg"} alt="" />
          </Link>
        </h1>
        <StyledAside isOpen={isOpen}>
          <Navigation
            isSearchOpen={isSearchOpen}
            closeMenuAction={closeMenuAction}
            mainMenuItems={mainMenuItems}
          />
          {isDesktop && (
            <Search onSearchClose={closeSearch} onSearchOpen={openSearch} />
          )}
          <NavIcons navIcons={navIcons} />
        </StyledAside>
        {!isDesktop && (
          <Search onSearchClose={closeSearch} onSearchOpen={openSearch} />
        )}
        <StyledMobileButton onClick={toggleMenu} isOpen={isOpen}>
          {isOpen ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" />
            </svg>
          )}
        </StyledMobileButton>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {
  navIcons: PropTypes.array,
  mainMenuItems: PropTypes.array,
}
export default Header
