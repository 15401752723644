import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { getLanguage } from "../../utils"
import { Link } from "gatsby"

const StyledNavigationWrapper = styled.div`
  @media (min-width: 769px) {
    margin-left: auto;
  }
`

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;

  @media (max-width: 769px) {
    flex-direction: column;
    justify-content: center;
  }
`

const StyledListItem = styled.li`
  display: flex;
  margin: 0;
  padding: 0 1.5rem;
  justify-content: center;

  @media (min-width: 769px) {
    &:last-child {
      padding-right: 0;
    }
  }
`

const sharedLinkStyle = css`
  color: ${props => props.theme.oster.textAlt};
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-weight: normal;

  transition: all 300ms linear;

  &:hover {
    color: ${props => props.theme.oster.textSecond};
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  ${sharedLinkStyle}
`

const StyledExternalLink = styled.a`
  ${sharedLinkStyle}
`

const Navigation = ({ footerItems = [] }) => {
  const [isBottom, setIsBottom] = useState(false)

  const calculatePosition = useCallback(() => {
    setIsBottom(
      window.scrollY + window.innerHeight >= document.body.scrollHeight,
    )
  }, [setIsBottom])

  useEffect(() => {
    window.addEventListener("scroll", calculatePosition)

    return () => window.removeEventListener("scroll", calculatePosition)
  }, [isBottom, calculatePosition])

  return (
    <StyledNavigationWrapper>
      <nav>
        <StyledList>
          {footerItems
            .sort((a, b) => a.node.order - b.node.order)
            .filter(({ node }) => node.url !== "#")
            .map(({ node }) => {
              return (
                <StyledListItem key={node.contentful_id}>
                  {node.isExternal ? (
                    <StyledExternalLink
                      href={node.url}
                      data-text={node.title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {node.title}
                    </StyledExternalLink>
                  ) : (
                    <StyledLink
                      to={`/${getLanguage()}/${node.url}`}
                      data-text={node.title}
                    >
                      {node.title}
                    </StyledLink>
                  )}
                </StyledListItem>
              )
            })}
          <StyledListItem>
            <StyledExternalLink
              href="https://osterhome.eu"
              data-text="osterhome.eu"
            >
              osterhome.eu
            </StyledExternalLink>
          </StyledListItem>
        </StyledList>
      </nav>
    </StyledNavigationWrapper>
  )
}

Navigation.propTypes = {
  footerItems: PropTypes.array,
}
export default Navigation
